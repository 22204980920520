<template>
    <modal ref="modalEditarDestinatarios" titulo="Editar destinatarios" adicional="Guardar" tamano="modal-md" @adicional="actualizarDestinatarios">
        <ValidationObserver tag="div" class="container">
            <div class="d-flex align-items-center mb-2">
                <el-switch v-model="ponerNullCantidadPermitida" :active-value="1" :inactive-value="0" active-color="#00D9B5" inactive-color="#E6E6E6"/>
                <span class="ml-2">Todos los residentes pueden contestar</span>
            </div>
            <div v-show="cantidad_permitida"  class="row mx-0">
                <p class="f-12 pl-2 label-inputs">Máximos de encuestados por vivienda</p>
                <el-input placeholder="Escribe el valor del elemento" v-model="cantidad_permitida" size="small"></el-input>
            </div>

            <div class="d-flex align-items-center mt-4">
                <el-switch v-model="soloGrupo" :active-value="0" :inactive-value="1" active-color="#00D9B5" inactive-color="#E6E6E6"/>
                <span class="ml-2">Todas las viviendas pueden contestar</span>
            </div>
            <div v-if="soloGrupo" class="row mx-0 my-4">
                <p class="f-12 pl-2 label-inputs">Destinatarios</p>
                <el-select v-model="grupoSelected" placeholder="Seleccionar grupo" size="small" filterable remote :remote-method="remoteMethod" class="w-100 mb-3" @change="adicionarDestinatario">
                    <el-option
                    v-for="(grupo,index) in gruposSearch"
                    :key="index"
                    :label="grupo.nombre"
                    :value="grupo.id"
                    :disabled="grupo.disabled"
                    >
                    </el-option>
                </el-select>
                <div class="col-12 text-86">
                    <p v-for="(grupo,index) in gruposViviendas" :key="index" class="mb-2 br-20 bg-f5 f-14 px-2 py-1 border d-flex justify-content-between">{{ grupo.nombre }} 
                        <i v-show="gruposViviendas.length > 1" class="icon-close cr-pointer f-12 my-auto" @click="eliminarGrupo(grupo.id_vivienda,index)"/> 
                    </p>
                </div>
            </div>
        </ValidationObserver>
    </modal>
</template>
<script>
import Encuestas from '@/services/encuestas/index'
import Viviendas from '@/services/viviendas/index'
export default {
    data(){
        return{
            cantidad_permitida:null,
            checkedTodos: false,
            checkedTodosPueden: false,
            inputDestinatarios:'',
            ponerNullCantidadPermitida: 1,
            grupoSelected: null,
            soloGrupo: 0,
            gruposViviendas: [],
            gruposSearch: []
        }
    },
    watch: {
        ponerNullCantidadPermitida(){
          this.cantidad_permitida = this.ponerNullCantidadPermitida == 1 ? null : 3;
        },
    },
    methods:{
        toggle(encuesta){
            this.gruposViviendas = []
            if(encuesta.cantidad_permitida) {
                this.cantidad_permitida = encuesta.cantidad_permitida
                this.ponerNullCantidadPermitida = 0
            }
            if(encuesta.arrayGrupos.length) {
                this.gruposViviendas = encuesta.arrayGrupos
                this.soloGrupo = 1
            }
            this.$refs.modalEditarDestinatarios.toggle()
        },
        remoteMethod(search){
            setTimeout(() => {
                this.loading = false;
                this.search(search)
            }, 100);
        },
        async search(search){      
            try {
                let idsViviendasYaAgregadas = this.gruposViviendas.map(el=>{ return el.id_vivienda})
                
                const {data} = await Viviendas.agrupaciones({search: search})
                
                this.gruposSearch = data.data.map(el=>{
                    if(idsViviendasYaAgregadas.includes(el.id)) el.disabled = true
                    return el
                })

            } catch (e){
                this.errorCatch(e)
            }
        },
        async adicionarDestinatario(){
            try {
                let params = {
                    id_encuesta: this.$route.params.id_encuesta,
                    id_vivienda: this.grupoSelected
                }
                const { data } = await Encuestas.adicionarDestinatario(params)

                let objFound = this.gruposSearch.find(el=>el.id == this.grupoSelected)
                objFound.id_vivienda = objFound.id
                this.gruposViviendas.unshift(objFound)

                this.refreshViviendasDisabled()
                this.grupoSelected = null
                this.notificacion('','Vivienda añadida correctamente','success')

            } catch (error) {
                this.errorCatch(error)
            }
        },
        async eliminarGrupo(idGrupo,index){
            try {
                let params = {
                    id_vivienda: idGrupo,
                    id_encuesta: this.$route.params.id_encuesta
                }
                const { data } = await Encuestas.eliminarDestinatario(params)
                
                this.gruposViviendas.splice(index,1)
                this.notificacion('','Grupo eliminado correctamente','success')

                this.refreshViviendasDisabled()
            } catch (error) {
                this.errorCatch(error)
            }
        },
        actualizarDestinatarios(){
            if(this.cantidad_permitida && (!Number(this.cantidad_permitida)  || this.cantidad_permitida > 255)) return this.notificacion('','El campo máximo de encuestados debe ser un número valido o menor a 255')
            if(!this.gruposViviendas.length && this.soloGrupo == 1) return this.notificacion('','Por favor añade al menos un grupo de viviendas')
            let obj = {
                cantidad_permitida: this.cantidad_permitida,
                solo_grupo: this.soloGrupo
            }
            this.$emit('actualizar',obj)

            this.$refs.modalEditarDestinatarios.toggle()

        },
        refreshViviendasDisabled(){
            let idsViviendasYaAgregadas = this.gruposViviendas.map(el=>{ return el.id_vivienda})

            this.gruposSearch.map(el=> {
                if(idsViviendasYaAgregadas.includes(el.id)) el.disabled = true
                else el.disabled = false
                return el
            })
        }
    }
}
</script>